import React from "react";
import axios from "../../utils/axios";
import "./Login.scss";
import { Header, Container, Grid, Confirm } from "semantic-ui-react";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrcode: "https://wetaocdn.0g1s.com/20210418/FnVJD08A23RgNgzpQOkLDq0Xojho.png",
      scene: "",
      isScan: false,
    };

    this.getLoginQrcode();
    this.checkLoginQrcode();
  }

  getLoginQrcode = () => {
    axios.get("/v1/auth/wetao/login").then(response => {
      this.setState({
        qrcode: response.url,
        // scene: "signup_606c1a527f7bc"
        scene: response.scene
      });
    }).finally(() => { });
  };

  checkLoginQrcode = () => {
    let loginCheckTimer = setInterval(() => {
      axios.get("/v1/auth/wetao/logincheck", {
        params: {
          scene: this.state.scene
        }
      }).then(response => {
        if (response.status === 1) {
          this.setState({
            isScan: response.status === 1
          });
          window.localStorage.setItem('token', response.token);
          clearInterval(loginCheckTimer);
        }
      }).finally(() => { });
    }, 1000);
  };

  handleScanConfirm = () => window.location.href = "https://wetao.0g1s.com/console/dashboard"
  handleScanCancel = () => window.location.href = "https://wetao.0g1s.com/"

  render() {
    return (
      <div className="login">
        <section className="hero background">
          <Container textAlign={"center"}>
            <Header size={"huge"} inverted>扫码登录/注册</Header>
            <Header size={"medium"} inverted>将微信与微淘账户绑定，下次可直接微信扫码登录</Header>
            {/* <p className="desc">有任何技术需求，欢迎来撩</p> */}
          </Container>
        </section>

        <section className="hero">
          <Container textAlign="center">
            <Grid stackable columns="1">
              <Grid.Column>
                <div className="qrcode">
                  <img src={this.state.qrcode} alt="qrcode" height="200" />
                  <div className="title">微信扫一扫，关注后登录注册</div>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </section>

        <Confirm
          header="扫码成功"
          open={this.state.isScan}
          content='是否前往商户后台？'
          cancelButton='随便看看'
          confirmButton="立即前往"
          onCancel={this.handleScanCancel.bind(this)}
          onConfirm={this.handleScanConfirm}
          size="mini"
        />
      </div>
    );
  }
}
