import axios from 'axios'

let config = {
  baseURL: process.env.REACT_APP_BASE_URL || "",
  timeout: 30 * 1000, // Timeout
};

const _axios = axios.create(config);

_axios.interceptors.response.use(
  (response) => {
    if (response.data.code !== 0) {
      return Promise.reject(response.data.message);
    }
    return response.data.data;
  },
  () => {
    return Promise.reject("系统维护中，等十分钟后再来~");
  }
);

export default _axios;
