import React from "react";
import axios from "../../utils/axios";
import "./Cases.scss";
import { Card, Container, Grid, Header, Icon, Image } from "semantic-ui-react";

export default class Cases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gzh: [],
      miniprogram: []
    };

    this.getCases();
  }

  getCases = () => {
    axios.get("/v1/home/cases").then(response => {
      this.setState({
        gzh: response.gzh,
        miniprogram: response.miniprogram
      });
    }).finally(() => { });
  };

  render() {
    return (
      <div className="cases">
        <section className="hero background">
          <Container textAlign={"center"}>
            <Header size={"huge"} inverted>经典案例</Header>
            <Header size={"medium"} inverted>每一个接入的公众号/小程序，都经过精雕细琢，最终完美交付</Header>
            <p className="desc">有任何技术需求，欢迎来撩</p>
          </Container>
        </section>

        <section className="hero">
          <Container>
            <Grid columns={2}>
              <Grid.Row columns={2}>
                <Grid.Column width={8} textAlign={"left"}>
                  <Header size={"medium"}>
                    <Icon name={"wechat"} />
                    <Header.Content>公众号</Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid stackable columns={8}>
              {this.state.gzh.map(item => (
                <Grid.Column width={2} key="item.name">
                  <div>
                    <Card fluid>
                      <Image src={item.avatar} wrapped ui={false} />
                      <Card.Content>
                        <Card.Description textAlign="center">{item.name}</Card.Description>
                      </Card.Content>
                    </Card>
                  </div>
                </Grid.Column>
              ))}
            </Grid>
          </Container>
        </section>

        <section className="hero gray">
          <Container>
            <Grid columns={2}>
              <Grid.Row columns={2}>
                <Grid.Column width={8} textAlign={"left"}>
                  <Header size={"medium"}>
                    <Icon name={"linkify"} />
                    <Header.Content>小程序</Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid stackable columns={8}>
              {this.state.miniprogram.map(item => (
                <Grid.Column width={2} key="item.name">
                  <div>
                    <Card fluid>
                      <Image src={item.avatar} wrapped ui={false} />
                      <Card.Content>
                        <Card.Description textAlign="center">{item.name}</Card.Description>
                      </Card.Content>
                    </Card>
                  </div>
                </Grid.Column>
              ))}
            </Grid>
          </Container>
        </section>
      </div>
    );
  }
}
