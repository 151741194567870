import React from "react";
import {Link, Route, Switch} from "react-router-dom";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import Prices from "./pages/prices/Prices";
import NotFound from "./pages/common/NotFound";
import {Menu, Sidebar} from "semantic-ui-react";
import Cooperation from "./pages/cooperation/Cooperation";
import Cases from "./pages/cases/Cases";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  hideSidebar = () => this.setState({visible: false})

  render() {
    return (
      <div>
        <Sidebar.Pushable>

          <Sidebar
            as={Menu}
            animation={"push"}
            direction={"right"}
            width={"thin"}
            vertical
            visible={this.state.visible}
          >
            <Link to={"/"} onClick={this.hideSidebar}>
              <Menu.Item
                name="home"
              >
                首页
              </Menu.Item>
            </Link>

            {/* <Link to={"/wxbox"} onClick={this.hideSidebar}>
              <Menu.Item
                name="wxbox"
              >
                微信域名检测
              </Menu.Item>
            </Link> */}

            <Link to={"/cases"} onClick={this.hideSidebar}>
              <Menu.Item
                name="cases"
              >
                案例
              </Menu.Item>
            </Link>

            <Link to={"/prices"} onClick={this.hideSidebar}>
              <Menu.Item
                name="prices"
              >
                定价
              </Menu.Item>
            </Link>

            <Link to={"/cooperation"} onClick={this.hideSidebar}>
              <Menu.Item
                name="cooperation"
              >
                合作
              </Menu.Item>
            </Link>

          </Sidebar>

          <Sidebar.Pusher>
            <Header handleVisible={() => {
              this.setState({visible: !this.state.visible})
            }} />
            <Switch>
              <Route path={"/cases"}>
                <Cases/>
              </Route>
              <Route path={"/cooperation"}>
                <Cooperation/>
              </Route>
              <Route path={"/login"}>
                <Login/>
              </Route>
              <Route path={"/prices"}>
                <Prices/>
              </Route>
              {/* <Route path={"/wxbox"}>
                <Wxbox/>
              </Route> */}
              <Route exact path={"/"}>
                <Home/>
              </Route>
              <Route path={"*"}>
                <NotFound/>
              </Route>
            </Switch>
            <Footer/>
          </Sidebar.Pusher>

        </Sidebar.Pushable>
      </div>
    );
  }
}
