import React from "react";
import { Container, Divider, Grid, Header } from "semantic-ui-react";
import {Link} from "react-router-dom";
import "./Home.scss"

export default class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <section className="hero transparent top-wrap">
          <span className="hero-wave-top" />
          {/* <span className="hero-wave" /> */}
          <Container>
            <div className="top-wrap-left">
              <Header size="huge">微淘 - 淘客界的瑞士军刀</Header>
              <Header size="medium">我们致力于打造完善的淘客行业解决方案，为您提供全方位一站式服务，助力淘客成功，加快业务增长。</Header>
              <div className="btns">
                <a href={"#section-contact"} className="large ui button primary btn-contact"><i className="icon comment"/> 立即咨询</a>
                <Link to={"/cooperation"} className="large ui button green"><i className="icon handshake"/> 交流合作</Link>
              </div>
            </div>
            <img className="banner" src="https://wetaocdn.0g1s.com/20210404/FjHnVlzRGzsjUPX3j1lEE-lm3xA8.svg" alt="banner" />
          </Container>
        </section>
        <section className="hero gray">
          <Container>
            <Divider horizontal>热门活动不要错过！</Divider>
            <Grid stackable columns={4}>
              <Grid.Row centered columns={4}>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconrili1" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">打卡签到</div>
                    <p>增加用户粘度，提高复购率</p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconyaoqing" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">邀请助力</div>
                    <p>低成本拉新，扩大影响力</p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconzhuanquyongjin" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">自动返利</div>
                    <p>下单返利，经久不衰</p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconlucky" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">幸运抽奖</div>
                    <p>趣味玩法，促活利器</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </section>

        <section className="hero transparent bg-hello">
          <Container textAlign="center">
            <div className="content">
              <Header size="huge">承接各类定制化需求</Header>
              <Header size="medium">不要让技术成为你想法的绊脚石</Header>
            </div>
          </Container>
        </section>

        <section className="hero gray">
          <Container>
            <Divider horizontal>实用工具让你爱不释手！</Divider>
            <Grid stackable columns={4}>
              <Grid.Row centered columns={4}>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconhb" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">口令红包</div>
                    <p>宠粉福利，推文必备</p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconurl" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">短链服务</div>
                    <p>高并发设计，毫秒级响应</p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#icontixing" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">提醒助手</div>
                    <p>定时提醒，收益翻番</p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconxingbie" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-title">个性皮肤</div>
                    <p>多皮肤选择，风格由你定</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </section>

        <section className="hero">
          <Container textAlign="center">
            <Divider horizontal>Backers</Divider>
            <div className="content">
              <Header size="huge">合作伙伴</Header>
              <Header size="medium">期待与您合作，共创美好未来</Header>
              <Header size="small">您的每一份信任，我们必万倍努力回报之</Header>
              <Grid stackable columns={4}>
                <Grid.Column>
                  <a href="https://www.wechat.com/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210411/FgpQVJZqRzaMIERZNjFH6NYQgfUe.svg" alt="Wechat"
                      height="100" />
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://www.taobao.com/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210411/FpB32AbrWIvSNLgs9ROVl2c8kPdQ.svg" alt="Taobao"
                      height="100" />
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://www.ele.me/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210404/Fl0rmkSLoP_hloRuce0sTnrVhndb.svg" alt="Eleme"
                      height="100" className="eleme" />
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://www.meituan.com/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210404/FkLC7etLS5iTxx8syC1_-lFheI2p.png" alt="Meituan"
                      height="100" className="meituan" />
                  </a>
                </Grid.Column>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="hero gray" id="section-contact">
          <Container textAlign="center">
            <Divider horizontal>联系我们</Divider>
            <Grid stackable columns="1">
              <Grid.Column>
                <div className="qrcode">
                  <div className="title">微信</div>
                  <img src="https://wetaocdn.0g1s.com/20210404/FqZGgjdjad7ejGKHoqop0q_PZ5OO.jpeg" alt="微信" height="200" />
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </section>
      </div>
    )
  }
}
