import React from "react";
import { Container, Grid, Icon } from "semantic-ui-react";
import './Footer.scss'

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <Container className='main'>
          <Grid columns={5}>
            <Grid.Column width={16} className={"show"}>
              <div className="title">微淘 - 淘客界的瑞士军刀</div>
              <div className="subtitle">专为淘客提供全方位一站式服务</div>
            </Grid.Column>
            <Grid.Column width={8} className={"hide"}>
              <div className="title">微淘 - 淘客界的瑞士军刀</div>
              <div className="subtitle">我们致力于打造完善的淘客行业解决方案，为您提供全方位一站式服务，助力淘客成功，加快业务增长。</div>
            </Grid.Column>
            <Grid.Column width={2} className={"hide"}>
              <h3>产品功能</h3>
              <div className="item">
                <a href="/" target="_blank" rel="noopener noreferrer">口令红包</a>
              </div>
              <div className="item">
                <a href="/" target="_blank" rel="noopener noreferrer">打卡签到</a>
              </div>
              <div className="item">
                <a href="/" target="_blank" rel="noopener noreferrer">邀请助力</a>
              </div>
              <div className="item">
                <a href="/" target="_blank" rel="noopener noreferrer">返佣平台</a>
              </div>
            </Grid.Column>
            <Grid.Column width={2} className={"hide"}>
              <h3>联系我们</h3>
              <div className="item">
                <a href="https://blog.imxfly.com/" target="_blank" rel="noopener noreferrer">博客</a>
              </div>
              <div className="item">
                <a href="https://twitter.com/imxfly" target="_blank" rel="noopener noreferrer">Twitter</a>
              </div>
              <div className="item">
                <a href="https://weibo.com/imxfly" target="_blank" rel="noopener noreferrer">微博</a>
              </div>
            </Grid.Column>
            <Grid.Column width={2} className={"hide"}>
              <h3>帮助支持</h3>
              <div className="item">
                <a href="/">常见问题</a>
              </div>
              <div className="item">
                <a href="/">报告Bug</a>
              </div>
              <div className="item">
                <a href="/">更新日志</a>
              </div>
            </Grid.Column>
            <Grid.Column width={2} className={"hide"}>
              <h3>更多产品</h3>
              <div className="item">
                <a href="https://aiioii.com" target="_blank" rel="noopener noreferrer">九一裂变</a>
              </div>
              <div className="item">
                <a href="https://wm.0g1s.com" target="_blank" rel="noopener noreferrer">外卖之光</a>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
        <div className='copyright'>
          <Container>
            <Grid columns={2}>
              <Grid.Column width={9} textAlign={"left"}>
              © 2019-至今 0g1s.com 版权所有
              </Grid.Column>
              <Grid.Column width={7} textAlign={"right"}>
                <span style={{ marginRight: '3px' }}>Made</span>
                <Icon name={"heart"} color={"red"} />
                <span>with 0g1s Team</span>
              </Grid.Column>
            </Grid>
          </Container>
          <Container>
            <Grid columns={1}>
              <Grid.Column width={16}>
                <img style={{ marginRight: '10px', marginBottom: '3px' }} src="https://wetaocdn.0g1s.com/20210418/FinEALw7ifYIV2baxOAzDe1ctz1S.png" alt="beian" />
                <a className="external-source-link" href="https://beian.miit.gov.cn">浙公网安备 33018302001175号</a>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      </footer>
    )
  }
}
