import * as React from "react";
// import axios from "../../utils/axios";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Message,
  Modal,
} from "semantic-ui-react";
import "./Prices.scss";

export default class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTipOpen: false,
      message: "",
    };
  }

  handleTipClose = () => this.setState({ isTipOpen: false });

  render() {
    return (
      <div className='prices'>
        <section className="hero background">
          <Container textAlign={"center"}>
            <Header size={"huge"} inverted>产品定价</Header>
            <Header size={"medium"} inverted>实惠的价格，可靠的服务</Header>
            <p className="desc">淘客界的瑞士军刀，确定不来一把？</p>
          </Container>
        </section>
        <section className="hero ">
          <Container>
            <Divider horizontal>产品优势</Divider>
            <Grid stackable columns={4}>
              <Grid.Row columns={4}>
                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconkekao" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">安全可靠</div>
                    </div>
                    <p>授权式接入，无需单独对接</p>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#icongaosu" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">极速稳定</div>
                    </div>
                    <p>高并发设计，毫秒级响应</p>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconfuwu" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">优质服务</div>
                    </div>
                    <p>7*24技术支持，专属客服一对一</p>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconjiage" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">实惠价格</div>
                    </div>
                    <p>行业最低价格，为您节省开支</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </section>
        <section className="hero gray">
          <Container>
            <Divider horizontal>热门套餐</Divider>
            <Message warning>
              <p>
                <Icon name='heart' color='red' />
                <span className='tip'
                >限时优惠：口令红包买二送一！</span>
              </p>
            </Message>
            <Grid stackable columns={4} className='price-plan'>
              <Grid.Row columns={4}>
                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconrili1" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">打卡签到</div>
                    </div>
                    <div className="price">
                      ¥1580
                      <span>/年</span>
                    </div>
                    <p>或 ¥150/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconyaoqing" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">邀请助力</div>
                    </div>
                    <div className="price">
                      ¥1580
                      <span>/年</span>
                    </div>
                    <p>或 ¥150/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconzhuanquyongjin" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">自动返利</div>
                    </div>
                    <div className="price">
                      ¥2580
                      <span>/年</span>
                    </div>
                    <p>或 ¥250/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconlucky" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">幸运抽奖</div>
                    </div>
                    <div className="price">
                      ¥998
                      <span>/年</span>
                    </div>
                    <p>或 ¥100/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>
              </Grid.Row>
            
              <Grid.Row columns={4}>
                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconhb" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">口令红包</div>
                    </div>
                    <div className="price">
                      ¥1580
                      <span>/年</span>
                    </div>
                    <p>或 ¥150/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconurl" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">短链服务</div>
                    </div>
                    <div className="price">
                      ¥980
                      <span>/年</span>
                    </div>
                    <p>或 ¥100/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#icontixing" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">提醒助手</div>
                    </div>
                    <div className="price">
                      ¥980
                      <span>/年</span>
                    </div>
                    <p>或 ¥100/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>

                <Grid.Column width={4}>
                  <div className="callout">
                    <div className="callout-icon">
                      <i className="iconfont">
                        <svg className="iconfont" aria-hidden="true">
                          <use xlinkHref="#iconxingbie" />
                        </svg>
                      </i>
                    </div>
                    <div className="callout-head">
                      <div className="callout-title">个性皮肤</div>
                    </div>
                    <div className="price">
                      ¥580
                      <span>/年</span>
                    </div>
                    <p>或 ¥60/月</p>
                    <a href={"#section-contact"} className="large ui button primary fluid">马上咨询</a>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </section>
        <section className="hero " id="section-contact">
          <Container textAlign={"center"}>
            <Divider horizontal>立即咨询</Divider>
            <Grid stackable columns="1">
              <Grid.Column>
                <div className="qrcode">
                  <div className="title">微信</div>
                  <img src="https://wetaocdn.0g1s.com/20210404/FqZGgjdjad7ejGKHoqop0q_PZ5OO.jpeg" alt="微信" height="200" />
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </section>

        <Modal size='mini' open={this.state.isTipOpen} onClose={this.handleTipClose}>
          <Modal.Header>友情提示</Modal.Header>
          <Modal.Content>
            <p>{this.state.message}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              content='我知道了'
              onClick={this.handleTipClose}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
