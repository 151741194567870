import * as React from "react";
import { Button, Container, Divider, Grid, Header } from "semantic-ui-react";
import "./Cooperation.scss";

export default function Cooperation() {
  return (
    <div className={"cooperation"}>
      <section className="hero background">
        <Container textAlign={"center"}>
          <div className="content">
            <Header size={"huge"}>合作共赢</Header>
            <Header size={"small"}>我们提供如网站/H5/服务号/小程序的定制、模仿，各类实用工具开发等多种合作方式</Header>
            <p className="description">有任何技术需求，欢迎来撩～</p>
          </div>
        </Container>
      </section>

      <section className="hero py-2">
        <Container textAlign={"center"}>
          <Header size={"huge"}>需求定制</Header>
          <Header size={"medium"}>我们给您快速定制需求，速度就是一切！</Header>
          <Header size={"small"} color='grey'>您可能需要提前预约，以便于安排合适的时间</Header>
          <Button primary content='立即联系' icon='comment' labelPosition='left' size='large' className='contact' onClick={
            () => {
              window.open("https://wj.qq.com/s2/6810939/ed64/")
            }
          } />
        </Container>
      </section>

      <section className="hero gray">
        <Container textAlign="center">
          <Divider horizontal>Backers</Divider>
          <div className="content">
            <Header size="huge">合作伙伴</Header>
            <Header size="medium">期待与您合作，共创美好未来</Header>
            <Header size="small">您的每一份信任，我们必万倍努力回报之</Header>
              <Grid stackable columns={4}>
                <Grid.Column>
                  <a href="https://www.wechat.com/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210411/FgpQVJZqRzaMIERZNjFH6NYQgfUe.svg" alt="Wechat"
                      height="100" />
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://www.taobao.com/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210411/FpB32AbrWIvSNLgs9ROVl2c8kPdQ.svg" alt="Taobao"
                      height="100" />
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://www.ele.me/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210404/Fl0rmkSLoP_hloRuce0sTnrVhndb.svg" alt="Eleme"
                      height="100" className="eleme" />
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="https://www.meituan.com/" target="_blank" rel="noopener noreferrer" className="callout">
                    <img src="https://wetaocdn.0g1s.com/20210404/FkLC7etLS5iTxx8syC1_-lFheI2p.png" alt="Meituan"
                      height="100" className="meituan" />
                  </a>
                </Grid.Column>
              </Grid>
          </div>
        </Container>
      </section>

      <section className="hero">
        <Container textAlign="center">
          <Divider horizontal>联系我们</Divider>
          <Grid stackable columns="1">
              <Grid.Column>
                <div className="qrcode">
                  <div className="title">微信</div>
                  <img src="https://wetaocdn.0g1s.com/20210404/FqZGgjdjad7ejGKHoqop0q_PZ5OO.jpeg" alt="微信" height="200" />
                </div>
              </Grid.Column>
          </Grid>
        </Container>
      </section>
    </div>
  );
}
