import React from "react";
import { Container, Icon, Menu } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";

function LoginButton() {
  if (window.localStorage.getItem('token')) {
    return (
      <Menu.Menu size='large' position="right">
        <Menu.Item name='login' onClick={() => {
          window.location.href = "https://wetao.0g1s.com/console/dashboard"
        }}>进入控制台</Menu.Item>
      </Menu.Menu>
    )
  } else {
    return (
      <Menu.Menu size='large' position="right">
        <Link to="/login" className="flex"><Menu.Item name='login'>登录</Menu.Item></Link>
        <Link to="/login" className="flex"><Menu.Item name='signup'>注册</Menu.Item></Link>
      </Menu.Menu>
    )
  }
}

export default function Header(props) {

  const history = useHistory();

  return (
    <header>
      <Container className="pc">
        <Menu secondary size='large'>
          <Menu.Item name='home' onClick={() => {
            history.push("/");
          }}>
            <img
              className="ui logo"
              src="/text-logo.svg"
              alt="logo"
            />
          </Menu.Item>
          <Menu.Item name='cases' onClick={() => {
            history.push("/cases");
          }}>
            <Icon name="fire" color='red' />
            案例
          </Menu.Item>
          <Menu.Item name='prices' onClick={() => {
            history.push("/prices");
          }}>
            定价
          </Menu.Item>
          {/* <Dropdown item basic text='产品'>
            <Dropdown.Menu>
              <Dropdown.Item icon='wechat' text='微信宝' onClick={() => {history.push('/wxbox')}}/>
              <Dropdown.Item icon='copyright' text='专利宝'/>
            </Dropdown.Menu>
          </Dropdown> */}
          <Menu.Item name='cooperation' onClick={() => {
            history.push("/cooperation");
          }}>
            合作
          </Menu.Item>
          <LoginButton />
        </Menu>
      </Container>
      <Container className="mobile">
        <Menu secondary>
          <Menu.Item name='home' onClick={() => {
            history.push("/");
          }}>
            <img
              className="ui logo"
              src="/text-logo.svg"
              alt="logo"
            />
          </Menu.Item>

          <Menu.Item
            position={"right"}
            name='menu'
            onClick={props.handleVisible}
          >
            <Icon name="content" className={"m-0"} />
          </Menu.Item>

        </Menu>
      </Container>
    </header>
  );
}
